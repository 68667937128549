<template>
  <div class="popup" v-if="status">
    <!-- 蒙版 -->
    <!-- <div v-if="mask" class="mask" :style="getMaskColor" @click="hide"></div> -->
    <!-- 弹出框内容 -->
    <div
      class="position-fixed z-index content"
      :class="getBodyClass"
      :style="{ bottom: getBottom }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 是否开启蒙版
    mask: {
      type: Boolean,
      default: true
    },
    // 是否开启蒙版颜色
    maskColor: {
      type: Boolean,
      default: true
    },
    // 是否居中
    center: {
      type: Boolean,
      default: false
    },
    isTop: {
      type: Boolean,
      default: false
    },
    // 是否处于底部
    bottom: {
      type: Boolean,
      default: true
    },
    bodyBgColor: {
      type: String,
      default: 'white'
    },
    height: {
      type: String,
      default: '60%'
    },
    mbBottom: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      status: false,
      animationData: {},
      animation: null
    }
  },
  mounted() {},
  computed: {
    getMaskColor() {
      let i = this.maskColor ? 0.5 : 0
      return `background-color: rgba(0,0,0,${i});`
    },
    getBodyClass() {
      if (this.center) {
        // return 'left-0 right-0 bottom-0 top-0 flex align-center justify-center'
        return 'pcenter'
      }
      if (this.isTop) {
        return 'left-0 right-0 top-0'
      }
      let bottom = this.bottom
        ? 'left-0 right-0 bottom-0 toprounded'
        : 'rounded border'

      return `${this.bodyBgColor} ${bottom}`
    },
    getBottom() {
      // todo
      return ''
    }
  },
  methods: {
    show() {
      console.log('show')
      this.status = true
    },
    hide() {
      this.status = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  opacity: 1;
  box-shadow: 0px -1px 4px 1px rgba($color: #888, $alpha: 0.2);
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}
.pcenter {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 100vw;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex {
  display: flex;
  flex-direction: row;
}

.rounded {
  border-radius: 10rpx;
}

.toprounded {
  border-radius: 10rpx 10rpx 0 0;
}

.border {
  border-width: 1rpx;
  border-style: solid;
  border-color: #dee2e6;
}

.position-fixed {
  position: fixed;
}

.popup {
  z-index: 9999;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }
}

.z-index {
  z-index: 9999;
}
</style>
