<template>
  <div class="fd-select-box" @click="changeShow" v-click-outside="closeDialog">
    <p v-if="scoped.selected&&scoped.selected.code" class="selected">
      <van-image width="20" height="20" :src="scoped.selected.img" /><span class="code">{{scoped.selected.code}}</span> 
    </p>
    <p v-else>
      <span></span>
    </p>
    <span :class="['fd-arrow icon iconfont',{'fd-down':scoped.showFlag}]">&#xe6a4;</span>
    <ul class="fd-select-list" v-show="scoped.showFlag">
      <li v-for="(item,index) in list" :key="index+'select'" @click.stop="changeValue(item)" :class="{'active':scoped.selected&&item.id===scoped.selected.id}">
        <van-image width="20" height="20" :src="item.img" />
       <span class="code">{{item.code}}</span> 
      </li>
    </ul>
  </div>
</template>

<script>
import derections from './js/directions';
export default {
  name: 'fdSselect',
  model: {
    prop: 'selected',
    event: 'changeValue',
  },
  props: {
    list: {
      type: Array,

      required: true,
    },
    selected: Object,
    type:{
      type:String,
      default:"country"  //国旗country bank银行
    }
  },
  data () {
    return {
      scoped: {
        // 是否展示下面的列表
        showFlag: false,
        // 当前选中的
        selected: this.selected,
      },
    };
  },
  watch:{
    selected:{
      handler(v){
        this.scoped.selected=v

      },
      deep:true
    }
  },
  directives: { clickOutside: derections.clickOutside },
  methods: {
    // 值改变后传给父组件，因为组件定义了model，所以父组件相当于执行了绑定的model值=emit出去的值
    changeValue (item) {
      this.scoped.selected = item;
      this.scoped.showFlag = false;
      this.$emit('changeValue', this.scoped.selected);
    },
    // 改变下拉选项的显隐
    changeShow () {
      this.scoped.showFlag = !this.scoped.showFlag;
    },
    closeDialog () {
      this.scoped.showFlag = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import './css/iconFont/iconfont.css';
.fd-select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  // padding-right: 40px;
  padding-left: 10px;
  height: 100%;
  border-radius: 4px;
  color: #fff;
  font-size: 24px;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
  .fd-arrow {
    font-size: 50px;
    transition: all 200ms;
    color: #fff;
    &.fd-down {
      transform: rotate(180deg);
    }
  }

  .fd-select-list {
    position: absolute;
    width: 500px;
    max-height: 380px;
    overflow: auto;
    list-style: none;
    top: 100%;
    left: 0;
    background: #000;
    color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 9;

    li {
      width: 500px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      height: 68px;
      border-bottom: 1px solid #666;
      cursor: pointer;
      
      &:hover {
        background: rgba(65, 191, 138, 0.2);
      }

      &.active {
        background: rgba(65, 191, 138, 0.9);
        color: #fff;
      }
      .icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .selected{
    display: flex;
    align-items: center;
    height: 40px;
    margin: 0;
  }
  .code{
        margin-left: 20px;
        line-height: 40px;
        height: 40px !important;
      }

}
</style>
