<template>
  <div>
    <layout>
      <template #left>
        <div class="flexbox a-center title">
          <img class="smile-icon" src="@/static/images/icon/avatar.webp" alt="" />
          <div class="ml5" style="align-self: flex-end">
            <span class="f32">{{ info.mobile }} </span>
          </div>
        </div>
      </template>

      <template #title></template>

      <template #right>
        <img class="msg-icon" style="
			  width: 28px;
			  border-radius: 50%;
			  height: 28px;
			  position: absolute;
			  left: -30px;
			" src="@/static/images/icon/earth.png" alt="" @click="changlang()" />

        <img class="msg-icon" src="@/static/images/icon/chat.png" style="width: 32px; border-radius: 50%; height: 32px"
          alt="" @click="$router.push('/leaveMessage')" />

        <van-badge :content="info.msgcount" color="red" />
      </template>

      <template #content>
        <div class="content">

          <div class="header ml32 mr32 flexbox_row a-center mt50 flexbox a-center">
            <div class="avatar mr20">
              <img class="" src="@/static/images/icon/avatar.png">
            </div>
            <div class="user flexbox_column">

              <div class="flexbox_row name a-center">
                <span>{{info.username}}</span>
                <div class="copy">{{ $t('复制') }}</div>
              </div>
              <div class="vip mt14">
                {{info.vipname}}
              </div>
            </div>
          </div>
          <div class="card bg-card mt32 account">
            <div class="account-item flexbox_row  a-center">
              <span class="text-#fff opacity-60">{{ $t('总资产') }}
                (USDT)</span>
              <span class="text-20px">{{info.usdt}}</span>
            </div>
      <!--      <div class="flexbox_row account-item a-center mt32">
				<span class="text-#fff opacity-60">{{ $t('量化账户') }}
                (USDT)</span><span class="text-20px">{{info.rest_amount}}</span></div> -->
				<!----><!---->
            <div class="flexbox_row account-item a-center mt32"><span
                class="text-#fff opacity-60">{{ $t('利润资产') }}(USDT)</span><span class="text-20px">{{info.total_income}}</span></div>
            <div class="flexbox_row account-item a-center mt32"><span
                class="text-#fff opacity-60">{{ $t('储值金额') }}(USDT)</span><span class="text-20px">{{info.chongzhi_total}}</span></div>
          </div>
          <div class="entrance mt50 flexbox_row center mr32 ml32">
            <div class="entrance-item flexbox_column center" @click="handleTo('/serveWay')">
              <img class="w-78px h-66px" src="https://dogecoin-usdt.vip/assets/UTQWswrnRuB7Pp1GJs.png">
              <div class="entrance-item-title mt20">{{ $t('储值') }}</div>
            </div>
            <div class="entrance-item flexbox_column center" @click="handleTo('/drawings')">
              <img class="w-78px h-66px" src="https://dogecoin-usdt.vip/assets/g9BIxhcDOSBJNXYI2s.png">
              <div class="entrance-item-title mt20">{{ $t('提取') }}</div>
            </div>
            <div class="entrance-item flexbox_column center" @click="handleTo('/recharge')">
              <img class="w-78px h-66px" src="https://dogecoin-usdt.vip/assets/qWC9mgoVdhtVVHPWzU.png">
              <div class="entrance-item-title mt20">{{ $t('细节') }}</div>
            </div>
          </div>
          <div class="money center  card bg-card flexbox_column mt50">
            <div class="money-title">{{ $t('总收入') }}</div>
            <div class="mt30 money-desc">{{info.total_income}}</div>
            <div class="line mt30"></div>
            <div class="money-main flexbox_row a-center">
              <div class="money-main-day  flexbox_column center">
                <div class="money-main-day-title">{{ $t('今日佣金') }}</div>
                <div class="money-main-day-title-desc">{{info.today_income}}</div>
              </div>
              <div class="money-main-day flexbox_column center">
                <div class="money-main-day-title">{{ $t('今日收益') }}</div>
                <div class="money-main-day-title-desc">{{info.today_lirun_total}}</div>
              </div>
              <div class="money-main-day flexbox_column center">
                <div class="money-main-day-title">{{ $t('昨天的收益') }}</div>
                <div class="money-main-day-title-desc">{{info.yesterday_income}}</div>
              </div>
            </div>
          </div>
          <div class="invite flexbox_column card bg-card mt50">
            <div class="invite-title">{{ $t('下级邀请') }}</div>
            <div class="invite-item">
              <div class="invite-item-title">{{ $t('等级') }} 1</div>
              <div class="invite-item-desc flexbox_row a-center">
                <div class="unit">
                  <div class="unit-title">{{ $t('全部的') }}/{{ $t('有效的') }}</div>
                  <div class="unit-desc">0/0</div>
                </div>
                <div class="unit">
                  <div class="unit-title">{{ $t('获取佣金') }}</div>
                  <div class="unit-desc">0</div>
                </div>
              </div>
            </div>


            <div class="invite-item">
              <div class="invite-item-title">{{ $t('等级') }} 2</div>
              <div class="invite-item-desc flexbox_row a-center">
                <div class="unit">
                  <div class="unit-title">{{ $t('全部的') }}/{{ $t('有效的') }}</div>
                  <div class="unit-desc">0/0</div>
                </div>
                <div class="unit">
                  <div class="unit-title">{{ $t('获取佣金') }}</div>
                  <div class="unit-desc">0</div>
                </div>
              </div>
            </div>


            <div class="invite-item">
              <div class="invite-item-title">{{ $t('等级') }} 3</div>
              <div class="invite-item-desc flexbox_row a-center">
                <div class="unit">
                  <div class="unit-title">{{ $t('全部的') }}/{{ $t('有效的') }}</div>
                  <div class="unit-desc">0/0</div>
                </div>
                <div class="unit">
                  <div class="unit-title">{{ $t('获取佣金') }}</div>
                  <div class="unit-desc">0</div>
                </div>
              </div>
            </div>

          </div>
          <div class="menu">
            <div class="menu-item flexbox_row a-center">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/6(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center" @click="handleTo('/forgetpass')">
                <div class="menu-item-main-title">
                  {{ $t('安全中心') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div>

        <!--    <div class="menu-item flexbox_row a-center" @click="handleTo('/drawings')">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/7(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center">
                <div class="menu-item-main-title">
                    {{ $t('转移') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div> -->
            <div class="menu-item flexbox_row a-center" @click="tolianghua('/helpCenter',1)">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/8(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center">
                <div class="menu-item-main-title">
                   {{ $t('量化教程') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div>
            <div class="menu-item flexbox_row a-center" @click="tolianghua('/helpCenter',2)">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/9(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center">
                <div class="menu-item-main-title">
                    {{ $t('讯息') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div>
            <div class="menu-item flexbox_row a-center" @click="handleTo('/lang')">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/10(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center">
                <div class="menu-item-main-title">
                    {{ $t('语言设定') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div>
            <div class="menu-item flexbox_row a-center" @click="tolianghua('/helpCenter',2)">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/11(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center">
                <div class="menu-item-main-title">
                     {{ $t('常见问题') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div>
            <div class="menu-item flexbox_row a-center" @click="handleTo('/helpCenter')">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/12(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center">
                <div class="menu-item-main-title">
                     {{ $t('关于我们') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div>
           <!-- <div class="menu-item flexbox_row a-center" @click="downapp()">
              <div class="menu-item-icon" style="color: red;">
                <img src="@/static/images/icon/13(1)(1).png" >
              </div>
              <div class="menu-item-main flexbox_row a-center">
                <div class="menu-item-main-title">
                      {{ $t('下载APP') }}
                </div>
                <img src="@/static/images/icon/arrow.png" class="menu-item-main-arrow">
              </div>
        
            </div> -->
 
          </div>

          <div class="logout" @click="logout()">
            {{ $t('登出') }}
          </div>
        </div>
      </template>
    </layout>
    
  </div>
</template>

<script>
import {
  apiHangqing,
  apiRobots,
  apiUser_mas2,
  apibuyrobots,
  apimyrobots,
  apiwenzhang,
  getMoble,
  apimyshouyi,
  apizsrobots,
  apifeerobot,
  getjiqinum
} from '@/http/api/'
import CopyInput from '@/components/copy-input'
import pako from 'pako'
import { mapGetters } from 'vuex'
import {
  Button,
  Badge,
  NoticeBar,
  Swipe,
  SwipeItem,
  Col,
  Row,
  Cell,
  Field
} from 'vant'
export default {
  components: {
    [Badge.name]: Badge,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    CopyInput,
    [Cell.name]: Cell,
    [Field.name]: Field
  },
  data() {
    return {
      mycode: '1234567890',
      mylink: "https://www.588bc.com/xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxsassssssssssssssssssssssssssssssssssssssxxxxxxxx",
      inputValue: "",
      icon: {
        smile: require('@/assets/icon/smile-icon.png'),
        msg: require('@/assets/icon/bell-icon.png'),
        tz: require('@/assets/icon/tz.png')
      },
      bannerList: [
        require('@/assets/img/trq1.png'),
        require('@/assets/img/trq.png'),
        require('@/assets/img/YT.png'),
        require('@/assets/img/tm.png'),
        require('@/assets/img/58.jpg'),
        require('@/assets/img/173.jpg')
      ],
      show: true,
      btc: '',
      fil: '',
      eth: '',
      token: '',
      info: [],
      iosdown: '',
      anddown: '',
      geren: '',
      aituring: [],
      HQlist: [],
      msgtz: 0,
      lingai: [],
      str: '',
      str2: '',
      str3: '',
      scrollData: [
        {
          name: '+333***222',
          money: '+$12200.9'
        },
        {
          name: '+312***332',
          money: '+$3320.1'
        },
        {
          name: '+333***2442',
          money: '+$1200.6'
        },
        {
          name: '+333***222',
          money: '+$12200.9'
        },
        {
          name: '+312***332',
          money: '+$3320.1'
        },
        {
          name: '+333***2442',
          money: '+$1200.6'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['partnerList']),
    navList() {
      return [
        {
          title: this.$i18n.t('navbar.储值'),
          img: require('@/static/images/icon/1.png'),
          url: '/drawings'
        },
        {
          title: this.$i18n.t('navbar.提取'),
          img: require('@/static/images/icon/2.png'),
          url: '/helpCenter'
        },
        {
          title: this.$i18n.t('navbar.帮助'),
          img: require('@/static/images/icon/3.png'),
          url: '/joinUs'
        },

        {
          title: this.$i18n.t('navbar.团队'),
          img: require('@/static/images/icon/4.png'),
          url: '/actionCenter'
        }
      ]
    },
    navList2() {
      return [
        {
          title: this.$i18n.t('navbar.下载APP'),
          img: require('@/static/images/icon/5.png'),
          url: '/drawings'
        },
        {
          title: this.$i18n.t('navbar.活动'),
          img: require('@/static/images/icon/6.png'),
          url: '/helpCenter'
        },
        {
          title: this.$i18n.t('navbar.代理合作'),
          img: require('@/static/images/icon/7.png'),
          url: '/joinUs'
        },

        {
          title: this.$i18n.t('navbar.邀请朋友'),
          img: require('@/static/images/icon/8.png'),
          url: '/actionCenter'
        }
      ]
    }
  },
  created() {
    var token = localStorage.getItem('key')

    if (!token) {
      this.$router.replace('/login')
    }
    this.msgtz = localStorage.getItem('msg')

    this.token = token
    apiUser_mas2({
      userid: token
    }).then((res) => {
      // 未登录踢下线
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      // this.showdown = res.user.showdown
      this.info = res.user
      this.rate = res.user.rate
      this.iosdown = res.user.iosdown
      this.anddown = res.user.anddown
    })
    apiRobots({}).then((res) => {
      this.aituring = res.info
    })
    apimyrobots({ userid: token }).then((res) => {
      this.myaicount = res.info.length
    })
    apimyshouyi({ userid: token }).then((res) => {
      this.geren = res.info

      this.geren.geren_amount = res.info.geren_amount
    })
    // 0号机器人
    apizsrobots({ userid: token }).then((res) => {
      this.lingai = res.info
    })
  },
  mounted() {
    this.getHQ()
    if (this.msgtz == 1) {
      this.$refs.popup.show()
    }
    this.homeNotice()
    console.log(this.HQlist)
  },
  methods: {
    changlang() {
      this.$router.push('/lang')
    },
    homeNotice() {
      var tell = /(\d{2})\d*(\d{4})/
      let jiqiNum = Math.floor(Math.random() * 5 + 1)
      jiqiNum = this.getjiqinum(jiqiNum)
      let telNum = getMoble()
      telNum = telNum.replace(tell, '4****$2')

      this.str = telNum + this.$t('升级') + jiqiNum

      jiqiNum = Math.floor(Math.random() * 5 + 1)
      jiqiNum = this.getjiqinum(jiqiNum)
      telNum = getMoble()
      telNum = telNum.replace(tell, '4****$2')
      this.str2 = telNum + this.$t('升级') + jiqiNum

      jiqiNum = Math.floor(Math.random() * 5 + 1)
      jiqiNum = this.getjiqinum(jiqiNum)
      telNum = getMoble()
      telNum = telNum.replace(tell, '4****$2')
      this.str3 = telNum + this.$t('升级') + jiqiNum

      this.str3 = this.str + this.str3
    },
	handleTo(url) {
    alert(url)
    console.log(url.split('/'))
    if(val){
      this.$router.push({ path: url, query: { q: val } });
    }else{
      this.$router.push(url)
    }
	},
  tolianghua(url,val){
    this.$router.push({ path: url, query: { q: val } });
  },
	logout(){
		localStorage.removeItem("key");
		localStorage.removeItem("msg")
		 this.$router.push("/login");
	},
    // 转换机器人
    getjiqinum(num) {
      if (num == 0) {
        return this.$t('No.0')
      } else if (num == 1) {
        return this.$t('No.1')
      } else if (num == 2) {
        return this.$t('No.2')
      } else if (num == 3) {
        return this.$t('No.3')
      } else if (num == 4) {
        return this.$t('No.4')
      } else if (num == 5) {
        return this.$t('No.5')
      }
    },
    qianyuebuy() {
      this.$router.push({
        name: 'myRobot',
        query: {
          info: this.lingai,
          type: 100
        }
      })
    },
    closemsg() {
      this.msgtz = 0
      this.$refs.popup.hide()
      window.localStorage.removeItem('msg')
    },
    about() {
      window.location.href = 'https://web.plus500at.com/'
    },
    getHQ() {
      var that = this
      apiHangqing({}).then((res) => {
        res.forEach(function (item, index) {
          if (item.name == 'BTC' || item.name == 'ETH' || item.name == 'XCH') {
            that.HQlist.push(item)
          }
        })
        this.btc = res[0].last
        this.fil = res[1].last
        this.xch = res[2].last
        this.doge = res[3].last
        this.bch = res[4].last
        this.zec = res[5].last
        this.eth = res[6].last
        this.eos = res[7].last
        this.ltc = res[8].last
        this.etc = res[9].last
        this.sendSocket()
      })
    },
    sendSocket() {
      let t = this
      this.socket = new WebSocket('wss://api-aws.huobi.pro/ws')
      this.socket.onopen = this.open
      // 监听socket消息
      this.socket.onmessage = this.getMessage
    },
    open: function () {
      // console.log("socket连接成功");
      let arr = [
        'btcusdt',
        'filusdt',
        'xchusdt',
        'dogeusdt',
        'bchusdt',
        'zecusdt',
        'ethusdt',
        'eosusdt',
        'ltcusdt',
        'etcusdt'
      ]
      var _that = this.socket
      arr.forEach(function (item, index) {
        let subK = {
          // 订阅数据
          sub: 'market.' + item + '.kline.1min',
          id: item
        }
        _that.send(JSON.stringify(subK))
      })
    },
    getMessage: function (event) {
      let blob = event.data
      let reader = new FileReader()
      var _that = this
      reader.onload = function (e) {
        let ploydata = new Uint8Array(e.target.result)
        let msg = pako.inflate(ploydata, {
          to: 'string'
        })
        msg = JSON.parse(msg)
        //console.log(msg);
        // _that.lists = [];
        let name = ''
        let fu = ''
        let bili = '0.00'
        let color = ''
        let home = ''
        let zhi = ''
        let isUp = false
        if (msg.ch) {
          if (msg.ch == 'market.btcusdt.kline.1min') {
            name = 'BTC（USDT)'

            // 计算比例
            if (msg.tick.close >= _that.btc) {
              fu = '+'
              bili = ((msg.tick.close - _that.btc) / _that.btc) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.btc - msg.tick.close) / _that.btc) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }

            // _that.lists[0] = {'id':1,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};

            _that.HQlist[0].name = name
            _that.HQlist[0].val = msg.tick.vol
            _that.HQlist[0].change = fu + bili
            _that.HQlist[0].isUp = isUp
            _that.HQlist[0].last = msg.tick.close
          }
          if (msg.ch == 'market.filusdt.kline.1min') {
            name = 'FIL(USDT)'
            // 计算比例
            if (msg.tick.close >= _that.fil) {
              fu = '+'
              bili = ((msg.tick.close - _that.fil) / _that.fil) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.fil - msg.tick.close) / _that.fil) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }
            // _that.lists[1] = {'id':2,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
            _that.HQlist[1].name = name
            _that.HQlist[1].val = msg.tick.vol
            _that.HQlist[1].change = fu + bili
            _that.HQlist[1].last = msg.tick.close
            _that.HQlist[1].isUp = isUp
          }

          if (msg.ch == 'market.ethusdt.kline.1min') {
            name = 'ETH(USDT)'
            // 计算比例
            if (msg.tick.close >= _that.eth) {
              fu = '+'
              bili = ((msg.tick.close - _that.eth) / _that.eth) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.eth - msg.tick.close) / _that.eth) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }
            // _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
            _that.HQlist[2].name = name
            _that.HQlist[2].val = msg.tick.vol
            _that.HQlist[2].change = fu + bili
            _that.HQlist[2].isUp = isUp
            _that.HQlist[2].last = msg.tick.close
          }
        }
      }
      reader.readAsArrayBuffer(blob, 'utf-8')

      var timea = Math.round(new Date() / 1000)

      // console.log(this.HQlist);
      this.$forceUpdate()
      var _thats = this.socket
      setTimeout(function () {
        _thats.send(
          JSON.stringify({
            pong: timea
          })
        )
      }, 4000)
    },

    send: function () {
      var timea = Math.round(new Date() / 1000)
      var data = {
        pong: timea
      }
      console.log(data)
      this.socket.send(data)
    },
    downapp() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        window.location.href = 'https://plus500at.com/app.mobileconfig'
      } else {
        window.location.href = 'https://plus500at.com/app.apk'
      }
    },
    handleUrl(url) {
      window.location.href = url
    },
    // 切换语言
    changeL() {
      this.$i18n.locale = 'zh'
    },
    handleTo(url) {
      this.$router.push(url)
    },
    handleRobot() {
      this.$router.push('/robot')
    },
    // 购买
    handleRobot2(info, type) {
      this.$router.push({
        name: 'robot',
        query: {
          info: info,
          type: type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.smile-icon {
  width: 80px;
  height: 80px;
}

.title {
  line-height: 80px;
}

.msg-icon {
  width: 26px;
  height: 38px;
}

.ad-footer {
  width: fit-content;
  margin: 30px auto;
  background-color: #555555;
  border-radius: 50px;
  padding-right: 30px;
  justify-content: center;

  .ad-footer-icon {
    width: 60px;
    padding: 5px;
    height: 60px;
    font-size: 28px;
    border-radius: 50%;
    background-color: #656565;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.content {
  .header {
    .avatar {
      background-color: #000000;

      img {
        width: 100px;
        height: auto
      }
    }

    .user {
      .name {
        span {
          font-size: 28px;
          font-weight: bold;
        }

        .copy {
          font-size: 26px;
          background-color: #ffe500;
          padding: 5px 10px;
          border-radius: 10px;
          margin-left: 10px;
        }
      }

      .vip {
        font-weight: bold;
        color: #ffe500;
      }
    }

  }

  .account {

    .account-item {
      width: 100%;

      font-size: 26px;
      justify-content: space-between;
    }
  }

  .entrance {
    justify-content: space-between;

    .entrance-item {
      width: fit-content;
      font-size: 26px;
      padding: 30px;
      border-radius: 30px;
      background-color: #1f1f1f;

      img {
        width: 150px;
        height: auto;
      }
    }
  }

  .money {
    .money-title {
      font-weight: bold;
    }

    .money-desc {
      font-weight: bold;
      color: #ffe500;
      font-size: 36px;
    }

    .line {
      width: 100%;
      height: 1px;
      border-radius: 1px;
      background-color: #fff;
      opacity: 0.06;
    }

    .money-main {
      margin-top: 20px;
      width: 100%;

      .money-main-day {
        width: 33%;

        .money-main-day-title {
          width: fit-content;
        }

        .money-main-day-title-desc {
          margin-top: 10px;
          color: #ffe500;
        }
      }
    }
  }

  .invite {
    .invite-title {
      font-size: 32px;
    }

    .invite-item {
      background-color: #1c1c1c;
      padding: 20px;
      margin-top: 20px;
      border-radius: 30px;

      .invite-item-title {
        font-size: 28px;
      }

      .invite-item-desc {
        justify-content: space-between;

        .unit {
          margin-top: 20px;

          .unit-title {
            font-size: 26px;
          }

          .unit-desc {
            margin-top: 20px;
            font-size: 26px;
            color: #ffe500;
          }
        }
      }
    }
  }
  .menu{
    margin-top: 32px;
    .menu-item{
      padding: 0px 10px;
      margin-left: 32px;
      margin-right: 32px;
      justify-content: space-between;
      .menu-item-icon{
        width: 120px;
        border-radius: 50%;
        img{
          width: 120px;
          height: auto;
        }
      }
      .menu-item-main {
        width: calc(100% - 120px);
        box-sizing: border-box;
        padding-left: 20px;
        justify-content: space-between;
        .menu-item-main-title{
          width: fit-content;
        }
        img{
          width: 50px;
          height: auto;
        }
      }
    }
  }
  .logout{
    background-color: #ffe500;
    border-radius: 80px;
    padding: 30px 200px;
    margin: 50px auto 100px;
    color: #000000;
    text-align: center;
    width: fit-content;
    font-size:28px;
  }
}

.bg-card {
  background-color: #1f1f1f
}

.card {
  margin-left: 32px;
  margin-right: 32px;
  border-radius: 30px;
  padding: 30px;
  font-size: 28px;
}

.scroll-box {
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 100px;
}

.text-#fff {
  color: #fff;
}

.opacity-60 {
  opacity: 0.6;
}

.text-20px {
  font-size: 36px;
}
</style>