<template>
    <div class="marquee-wrap" :style="{'height': height + 'px'}">
      <ul class="marquee-list"
          :style="animateUpStyle"
          v-on:mouseover="myMouseover"
          v-on:mouseout="myMouseout"
      >
        <li v-for="(item, index) in listData" :key="index"
            :style="{'height': height + 'px', 'line-height': height + 'px'}">
          <slot :scrollItem="item"></slot>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "TextScrolling",
    props: {
      scrollData: {
        type: Array,
      },
      height: {
        type: Number,
        default: 40
      },
      delay: {
        type: Number,
        default: 500
      }
    },
    data() {
      return {
        animateUp: false,
        listData: [],
        timer: null
      }
    },
    mounted() {
      this.timer = setInterval(this.scrollAnimate, this.delay + 1000);
    },
    computed: {
      scrollDataProp() {
        return this.scrollData;
      },
      transitionDelay() {
        return this.delay / 1000
      },
      animateUpStyle() {
        if (this.animateUp) {
          return {
            transition: `all ${this.transitionDelay}s`,
            transform: `translateY(-${this.height*0.8}px)`
          }
        } else {
          return {}
        }
      }
    },
    watch: {
      scrollDataProp: {
        handler(newVal) {
          this.listData = newVal
        },
        immediate: true
      }
    },
    methods: {
      scrollAnimate() {
        this.animateUp = true
        setTimeout(() => {
          this.listData.push(this.listData[0])
          this.listData.shift()
          this.animateUp = false
        }, this.delay)
      },
      myMouseover() {
        clearInterval(this.timer)
      },
      myMouseout() {
        this.timer = setInterval(this.scrollAnimate, this.delay + 1000);
      }
    },
    destroyed() {
      clearInterval(this.timer)
    }
  }
  </script>
  
  <style scoped lang="less">
  .marquee-wrap {
    margin-right: 32px;
    border-radius: 50px;
    margin-top: 32px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #232323;
    margin-left: 32px;
    .marquee-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0;
  
      li {
        width: 100%;
        height: 100%;
        list-style: none;
      }
    }
  }
  </style>
  