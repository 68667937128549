<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('绑定钱包地址') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <div class="card">
        <div class="caput">
          <span
            class="btn"
            @click="btnEvent(item.value)"
            :class="selectData == item.value ? 'check' : ''"
            v-for="item in list"
            :key="item.value"
            >{{ item.label }}</span
          >
          <!-- <span class="btn" @click="btnEvent">ERC20</span>
          <span class="btn" @click="btnEvent">ERC20</span> -->
        </div>

        <van-field class="input" v-model="usdt" disabled/>

        <p class="text">{{$t('钱包地址')}}</p>
        <van-field
          class="input"
          v-model="address"
          :placeholder="$t('钱包地址')"
        />
        <div class="description">
          <div>
            {{
              $t(
                '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失'
              )
            }}
          </div>
        </div>
      </div>
    </div>
	<van-popup v-model="paypwd" round closeable @close="closepaypwd">
		  <div class="wt-select-dialog" style=" height: 8.00097rem;text-align: center;background-color: #2a2c2e">
		    <br>
		    <div class="wt-dialog_bd">
		
			<p style="color: #000000;text-align: center;z-index: 10; font-size: 18px; ">{{$t("请输入交易密码")}} <span style="color: #26B77E;"></span></p>
		     <div class="wt-card" style="min-height: 50px;text-align: center;">
		         <van-field class="input" v-model="paypasswd" :placeholder="$t('请输入交易密码')" />
		      </div>
		      <van-row >
		
				<div >
		        <van-col span="12" style="width: 90%; padding-top: 20px;">
		          <van-button  type="info" style="width: 5.5rem;background-color: #2a2c2e" @click="bandingqianbao">{{
		            $t("确定")
		          }}</van-button>
		        </van-col>
				</div>
		      </van-row>
		    </div>
		  </div>
		</van-popup>
    <!-- <div class="prompt">Cheqam在24小时内取款</div> -->
    <div class="footerBtn">
      <HBBTN @clickEvent="bandingqianbao">
        {{ $t('确认') }}
      </HBBTN>
    </div>
  </div>
</template>

<script>
// import ROBOT from '@/components/robot'
import HBBTN from '@/components/hb-btn'
import { NavBar, Field, Button, Tab,Popup, Tabs, Toast } from "vant";
import { apibindqianbao, getUrlKey, apigetaddres } from "@/http/api/";
import md5 from 'js-md5';
export default {
  name: 'welcome',
  components: {
    HBBTN,
	[Popup.name]: Popup,
  },
  data() {
    return {
      num: '',
      radio: 1,
      address: '',
      selectData: 2,
      list: [
         { label: 'ERC20', value: 1 },
        { label: 'TRC20', value: 2 },
        { label: this.$t('银行'), value: 3 }
      ],
	  usdt:"TRC20",
	  paypwd:false,
	  paypasswd:"",
	  type:2
    }
  },
	 created(e) {
	    var token = localStorage.getItem("key");
	    console.log("token", token);
	    if (!token) {
	      this.$router.replace("/login");
	    }
	    this.token = token;
	    var url = window.location.href;
	    //let type = getUrlKey("type", url);
	   
	
	    this.init();
	  },
  methods: {
	  bandingqianbao() {
	        if (!this.type) {
	          Toast.fail(this.$t("不能为空"));
	          return;
	        }
	        if (!this.address) {
	          Toast.fail(this.$t("不能为空"));
	          return;
	        }
	  	  // if (!this.paypasswd) {
	  	  //   Toast.fail(this.$t("不能为空"));
	  	  //   return;
	  	  // }
	        apibindqianbao({
	          userid: this.token,
	          type: this.type,
	          qianbao: this.address,
			  paypass:md5(this.paypasswd),
	        }).then((res) => {
	          console.log(res);
	          if (res.status == 1) {
	            setTimeout(() => {
	              Toast.success(this.$t(res.info));
	              this.$router.push("/bankCard");
	            }, 1000);
	          } else {
	            Toast.fail(this.$t(res.info));
	          }
	        });
	      },
	  init() {
	        apigetaddres({
	          userid: this.token,
	          type: this.type,
	        }).then((res) => {
	          this.address = res.info;
	        });
	      },
    confirmEvent() {
		this.paypwd = true;
      console.log('ceshi')
    },
    getBack() {
      this.$router.back()
    },
	closepaypwd(){
		this.paypwd = false;
	},
    btnEvent(_data) {
      this.selectData = _data
      if(_data == 3){
		  this.$router.push("/connectDebitCard");
	  }
	  if(_data == 1){
		  this.usdt = "ERC20";
		  this.type = 1;
	  }
	  if(_data == 2){
		  this.usdt = "TRC20";
		  this.type = 2;
	  }
	  this.init();
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 20px;
    background-color: #2a2c2e;
    .card {
      padding: 40px 26px;
      .caput {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        .btn {
          text-align: center;
          font-size: 30px;
          // line-height: 100px;
          height: 30px;
          padding: 20px 40px;
          border-radius: 15px;
          background-color: #fff;

          // width: 33%;
        }
        .check {
          background-color: #0d9135;
        }
      }

      .text {
        color: #fff;
      }
    }
    .description {
      color: #888;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
  .footerBtn {
    margin-top: 50px;
  }
}
</style>
