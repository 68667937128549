<template>
  <div>
    <layout :isShowFooter="false">
      <template #title>{{$t('设置交易密码')}}</template>
      <template #content>
        <div class="content">
          <van-form>
			  <div class="captcha">
			    <van-field
			      v-model="loginpwd"
			  	type="password"
			      :placeholder="$t('登录密码')"
			    />
			    
			  </div>
            <van-field
              class="input"
              v-model="password"
              type="password"
              :placeholder="$t('请输入交易密码')"
            />
            <van-field
              class="input"
              v-model="password2"
              type="password"
              :placeholder="$t('再次请输入交易密码')"
            />
            <!-- <div class="phone">
              <span class="text">手机号码 :</span>
              <span>176342231231</span>
            </div> -->
            
          </van-form>
          <div  @click="captchaEvent">
            <hbbtn>{{$t('确认')}}</hbbtn>
          </div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import hbbtn from '@/components/hb-btn'
import { NavBar, Button, Field,Toast } from "vant";

import { apipaypassword,apiUser_mas2,apipaysms } from "@/http/api/";
export default {
  components: {
    hbbtn
  },
  data() {
    return {
      textContent: '123',
      confirmPass: '',
      password: '',
      password2: '',
	  newpasswd:'',
	  loginpwd:'',
	  token:""
    }
  },
  created() {
    	var token = localStorage.getItem("key");
    	
    	if (!token) {
    	  this.$router.replace("/login");
    	}
    	this.token = token;
  
    },
  methods: {
    captchaEvent() {
      if(this.password != this.password2){
      			Toast.fail(this.$t('两次密码不一致'));
      			return;
      		}
       if(!this.loginpwd){
      			Toast.fail(this.$t('请输入登录密码'));
      			return;
      }
	  apipaypassword({
	  		  userid: this.token,	 
	  		  paypassword:this.password2,
	  		  loginpwd:this.loginpwd
	  		}).then((res) => {
	  		 if(res.status == 0){
	  			 Toast.success(this.$t(res.info));
	  			 // this.$router.push("/withdrawal-style");		 
	  			 this.$router.go(-1);
	  			
	  		 }else{
				 Toast.fail(this.$t(res.info));
	  			
	  		 }
	  		});
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  margin-top: 90px;
  padding: 0 36px;
  .prefix {
    width: 180px;
    margin-right: 30px;
    ::v-deep input.van-field__control {
      color: #0d9135;
    }
  }
}
.btn {
  box-shadow: 0px 10px 20px #0d9135;
  font-size: 30px;
  height: 103px;
  background-color: #0d9135;
  border: none;
  color: #000;
}
.captcha {
  height: 90px;
  padding: 10px;
  margin-bottom: 30px;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
  border-radius: 20px;
  .textBtn {
    width: 400px !important;
  }

  ::v-deep .van-button--plain.van-button--info {
    color: #0d9135;
  }
  ::v-deep .van-button--normal {
    background-color: #171717;
    border: none;
  }
}
.phone {
  margin: 50px 0 20px;
  color: #888;
  .text {
  }
}
</style>
