<template>
  <div>
    <!-- 客服 -->
    <div class="customer" @click="gokf">{{ $t('联系我们') }}</div>
    <layout>
      <template #left>
        <div class="flexbox a-center title">
          <img
            class="smile-icon"
            src="@/static/images/icon/avatar.webp"
            alt=""
          />
          <div class="ml5" style="align-self: flex-end">
            <span class="f32">{{ info.mobile }} </span>
          </div>
        </div>
      </template>

      <template #title></template>

      <template #right>
        <img
          class="msg-icon"
          style="
            width: 28px;
            border-radius: 50%;
            height: 28px;
            position: absolute;
            left: -30px;
          "
          src="@/static/images/icon/earth.png"
          alt=""
          @click="changlang()"
        />
        <!-- <img
          class="msg-icon"
          style="width: 28px; height: 28px; position: absolute; left: -20px"
          src="@/assets/app.png"
          alt=""
          @click="downapp()"
        /> -->
        <img
          class="msg-icon"
          src="@/static/images/icon/chat.png"
          style="width: 32px; border-radius: 50%; height: 32px"
          alt=""
          @click="$router.push('/leaveMessage')"
        />

        <van-badge :content="info.msgcount" color="red" />
      </template>

      <template #content>
        <div class="content">
          <div class="menus flexbox_column ml32 mr32 mt50">
            <div class="menu flexbox_row ml20 mr20">
              <div
                class="menu-item flexbox_column lcenter"
                v-for="(item, i) in navList"
                :key="i"
                @click="handleTo(item.url)"
              >
                <div class="cover menu-item flexbox_column center">
                  <img :src="item.img" />
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
            <div class="menu flexbox_row ml20 mr20">
              <div
                class="menu-item flexbox_column lcenter"
                v-for="(item, i) in navList2"
                :key="i"
                @click="handleTo(item.url)"
              >
                <div class="cover menu-item flexbox_column center">
                  <img :src="item.img" />
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="notice flexbox ml32 mr32 a-center mt32">
            <img class="tz-icon ml32" :src="icon.tz" alt="" />

            <marquee
              behavior="down"
              scrolldelay="100"
              class="ml32 bcolor"
              style="color: #fff"
            >
              {{ $t('恭喜新用户') }}{{ str }} | {{ $t('恭喜新用户')
              }}{{ str2 }} | {{ $t('恭喜新用户') }}{{ str3 }} |
            </marquee>
          </div>
          <div class="coins flexbox_row a-center mt30 ml32 mr32">
            <div class="coin flexbox_column">
              <div class="coin-title flexbox_row a-center">
                <img class="coin-icon" :src="coinList.btc" alt="" />
                <p class="ml20">BTC/USDT</p>
              </div>
              <div class="coin-value mt20">{{ klinedata[0].tick.close }}</div>
              <!-- <p class="coin-percent mt20">-0.22%</p> -->
              <p
                class="coin-percent mt20"
                v-show="klinedata[0].tick.close > klinedata[0].tick.open"
              >
                {{
                  (
                    ((klinedata[0].tick.close - klinedata[0].tick.open) /
                      klinedata[0].tick.open) *
                    100
                  ).toFixed(2)
                }}
                %
              </p>
              <p
                class="coin-percent mt20"
                v-show="klinedata[0].tick.close <= klinedata[0].tick.open"
              >
                -{{
                  (
                    ((klinedata[0].tick.close - klinedata[0].tick.open) /
                      klinedata[0].tick.open) *
                    100
                  ).toFixed(2)
                }}
                %
              </p>
            </div>
            <div class="coin flexbox_column">
              <div class="coin-title flexbox_row a-center">
                <img class="coin-icon" :src="coinList.eth" alt="" />
                <p class="ml20">ETH/USDT</p>
              </div>
              <div class="coin-value mt20">{{ klinedata[1].tick.close }}</div>

              <p
                class="coin-percent mt20"
                v-show="klinedata[1].tick.close > klinedata[1].tick.open"
              >
                {{
                  (
                    ((klinedata[1].tick.close - klinedata[1].tick.open) /
                      klinedata[1].tick.open) *
                    100
                  ).toFixed(2)
                }}
                %
              </p>
              <p
                class="coin-percent mt20"
                v-show="klinedata[1].tick.close <= klinedata[1].tick.open"
              >
                -{{
                  (
                    ((klinedata[1].tick.close - klinedata[1].tick.open) /
                      klinedata[1].tick.open) *
                    100
                  ).toFixed(2)
                }}
                %
              </p>
            </div>
          </div>
          <router-link to="/market">
            <div class="myad mr32 ml32 flexbox_column">
              <div class="myad-title">{{ $t('量化交易') }}</div>
              <div class="myad-subtitle">{{ $t('高效率稳定交易') }}</div>
            </div>
          </router-link>
          
          <router-link to="/team">
            <div class="invite flexbox_row a-center mr32 ml32 mt80">
            <div class="invite-title">{{ $t('邀请朋友') }}</div>
            <div class="invite-subtitle">{{ $t('赚取收入') }}</div>
            <img
              class="invite-hand"
              src="@/static/images/icon/hand.png"
              alt="邀请朋友"
            />
          </div>
          </router-link>
          

          <div class="markets mr32 ml32">
            <div class="markets-title">{{ $t('即时行情') }}</div>

            <div
              class="market mt30 bg-card"
              v-for="(item, i) in klinedata"
              :key="i"
            >
              <div class="market-title flexbox_row a-center">
                <img
                  class="market-title-icon"
                  v-show="item.icon == 'btc'"
                  :src="coinList.btc"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'eth'"
                  :src="coinList.eth"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'bnb'"
                  :src="coinList.bnb"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'xrp'"
                  :src="coinList.xrp"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'ada'"
                  :src="coinList.ada"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'sol'"
                  :src="coinList.sol"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'doge'"
                  :src="coinList.doge"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'dot'"
                  :src="coinList.dot"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'ltc'"
                  :src="coinList.ltc"
                  alt=""
                />

                <img
                  class="market-title-icon"
                  v-show="item.icon == 'trx'"
                  :src="coinList.trx"
                  alt=""
                />

                <div class="ml20 market-title-subtitle">
                  {{ item.coin }}<span class="ml5">/USDT</span>
                </div>
              </div>
              <div class="market-items flexbox_row mt32">
                <div class="market-item flexbox_column">
                  <div class="market-item-title flexbox_row a-center">
                    <img
                      class="market-item-title-icon"
                      src="@/static/images/icon/BINANCE.png"
                      alt=""
                    />
                    <p class="ml10">BINANCE</p>
                  </div>
                  <div
                    class="market-items-main flexbox_row a-center red"
                    v-show="item.tick.close > item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      {{
                        (
                          ((item.tick.close - item.tick.open) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>

                  <div
                    class="market-items-main flexbox_row a-center green"
                    v-show="item.tick.close <= item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      -{{
                        (
                          ((item.tick.open - item.tick.close) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>
                </div>
                <div class="market-item flexbox_column">
                  <div class="market-item-title flexbox_row a-center">
                    <img
                      class="market-item-title-icon"
                      src="@/static/images/icon/OKX.png"
                      alt=""
                    />
                    <p class="ml10">OKX</p>
                  </div>
                  <div
                    class="market-items-main flexbox_row a-center red"
                    v-show="item.tick.close > item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      {{
                        (
                          ((item.tick.close - item.tick.open) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>

                  <div
                    class="market-items-main flexbox_row a-center green"
                    v-show="item.tick.close <= item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      -{{
                        (
                          ((item.tick.open - item.tick.close) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>
                </div>
                <div class="market-item flexbox_column mt14">
                  <div class="market-item-title flexbox_row a-center">
                    <img
                      class="market-item-title-icon"
                      src="@/static/images/icon/HUOBI.png"
                      alt=""
                    />
                    <p class="ml10">HUOBI</p>
                  </div>
                  <div
                    class="market-items-main flexbox_row a-center red"
                    v-show="item.tick.close > item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      {{
                        (
                          ((item.tick.close - item.tick.open) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>

                  <div
                    class="market-items-main flexbox_row a-center green"
                    v-show="item.tick.close <= item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      -{{
                        (
                          ((item.tick.open - item.tick.close) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>
                </div>
                <div class="market-item flexbox_column mt14">
                  <div class="market-item-title flexbox_row a-center">
                    <img
                      class="market-item-title-icon"
                      src="@/static/images/icon/COINBASE.png"
                      alt=""
                    />
                    <p class="ml10">COINBASE</p>
                  </div>
                  <div
                    class="market-items-main flexbox_row a-center red"
                    v-show="item.tick.close > item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      {{
                        (
                          ((item.tick.close - item.tick.open) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>

                  <div
                    class="market-items-main flexbox_row a-center green"
                    v-show="item.tick.close <= item.tick.open"
                  >
                    <div class="market-items-main-value">
                      {{ item.tick.close }}
                    </div>
                    <div class="market-items-main-percent">
                      -{{
                        (
                          ((item.tick.open - item.tick.close) /
                            item.tick.open) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="market mt30 bg-card"> -->
            <!-- <div class="market-title flexbox_row a-center">
                <img
                  class="market-title-icon"
                  src="@/static/images/icon/coin.png"
                  alt=""
                />
                <div class="ml20 market-title-subtitle">BTC<span class="ml5">/USDT</span></div>
              </div> -->
            <!-- <div class="market-items flexbox_row mt32 "> -->
            <!-- <div class="market-item flexbox_column ">
                  <div class="market-item-title  flexbox_row a-center">
                  <img
                    class="market-item-title-icon"
                    src="@/static/images/icon/coin.png"
                    alt=""
                  />
                  <p class="ml10">USDT</p>
                </div>
                <div class="market-items-main flexbox_row  a-center">
                    <div class="market-items-main-value">585</div>
                    <div class="market-items-main-percent">-32.6%</div>
                  </div>
                </div>
                <div class="market-item flexbox_column ">
                  <div class="market-item-title  flexbox_row a-center">
                  <img
                    class="market-item-title-icon"
                    src="@/static/images/icon/coin.png"
                    alt=""
                  />
                  <p class="ml10">USDT</p>
                </div>
                <div class="market-items-main flexbox_row  a-center">
                    <div class="market-items-main-value">585</div>
                    <div class="market-items-main-percent">-32.6%</div>
                  </div>
                </div>
                <div class="market-item flexbox_column mt14">
                  <div class="market-item-title  flexbox_row a-center">
                  <img
                    class="market-item-title-icon"
                    src="@/static/images/icon/coin.png"
                    alt=""
                  />
                  <p class="ml10">USDT</p>
                </div>
                <div class="market-items-main flexbox_row  a-center">
                    <div class="market-items-main-value">585</div>
                    <div class="market-items-main-percent">-32.6%</div>
                  </div>
                </div> -->
            <!-- <div class="market-item flexbox_column mt14">
                  <div class="market-item-title  flexbox_row a-center">
                  <img
                    class="market-item-title-icon"
                    src="@/static/images/icon/coin.png"
                    alt=""
                  />
                  <p class="ml10">USDT</p>
                </div>
                <div class="market-items-main flexbox_row  a-center">
                    <div class="market-items-main-value">585</div>
                    <div class="market-items-main-percent">-32.6%</div>
                  </div>
                </div> -->
            <!-- </div> -->
            <!-- </div> -->
          </div>

          <text-scrolling :scrollData="scrollData" :height="40" :delay="1500">
            <template v-slot:default="scrollItem">
              <div class="scroll-box flexbox_row a-center ml32 mr32 mt50">
                <div>
                  {{ scrollItem.scrollItem.name }}
                </div>
                <div>
                  {{ scrollItem.scrollItem.money }}
                </div>
              </div>
            </template>
          </text-scrolling>

          <div class="cal flexbox_row a-center bg-card mt32 mr32 ml32">
            <div class="cal-item flexbox_column center">
              <div class="cal-item-title">安全操作</div>
              <div class="cal-item-content">{{ info.rest_day }}天</div>
            </div>
            <div class="cal-item flexbox_column center">
              <div class="cal-item-title">累计收入</div>
              <div class="cal-item-content">${{ info.total_income }}</div>
            </div>
          </div>
        </div>
      </template>
    </layout>

    <ROBOT />

    <popup center ref="popup">
      <div class="poptip">
        <div
          style="
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color: #fafafa;
            background: #1f1f1f;
            position: relative;
            border-radius: 8px 8px 0 0;
          "
        >
          <img
            style="
              position: absolute;
              top: -40px;
              left: 20px;
              width: 120px;
              height: 120px;
            "
            src="../../assets/img/labaicon.png"
            alt=""
          />
          <div style="color: #fff">最新公告</div>
        </div>
        <div
          v-html="info.text"
          style="
            height: 250px;
            padding: 10px;
            overflow-y: auto;
            overflow-x: hidden;
            color: #fff;
            background: #000000;
          "
          class="rich-text announce-content"
        >
          <!-- <p>線上客服：联系我们（7×24小时）</p>
			  <p>官方电报频道：
				  <a href="https://t.me/Dogecoin_Officia_lChannel" target="_blank" rel="noopener">
					  https://t.me/Dogecoin_Officia_lChannel
				  </a>
			  </p>
			  <p>平台上线时间：09月21日2024年</p>
			  <p>量化重置时间：美东时间上午12:00</p>
			  <p>&nbsp;</p>
			  <p>激活VIP1需要充值15 USDT(每日收益2.55USDT）</p>
			  <p>激活VIP2需要充值65 USDT(每日收益12USDT）</p>
			  <p>激活VIP3需要充值185 USDT(每日收益37USDT）</p>
			  <p>激活VIP4需要充值685 USDT(每日收益151USDT）</p>
			  <p>激活VIP5需要充值1385 USDT(每日收益333USDT）</p>
			  <p>激活VIP6需要充值3385 USDT(每日收益880USDT）</p>
			  <p>激活VIP7需要充值6985 USDT(每日收益1956USDT）</p>
			  <p>激活VIP8需要充值13885 USDT(每日收益4166USDT）</p>
			  <p>激活VIP9需要充值25885 USDT(每日收益9060USDT）</p>
			  <p>激活VIP10需要充值58888 USDT(每日收益22378USDT）</p>
			  <p>1:儲值投資只能使用USDT幣種並選擇TRC20或BEP20綱路.</p>
			  <p>2:最低充值金额:15 USDT(每日收益 2.55USDT)最低提款金额:2 USDT,無上限.</p>
			  <p>3:每天只能提幣一次,無時間限制，提幣手續費零,3分鐘内到賬.</p>
			  <p>&nbsp;</p>
			  <p>分享邀請連結到您的社群推廣,例如:Tiktok Facebook、Twitter、YouTube、Instagram、Kao、WhatsApp 群组、Telegram</p>
			  <p>邀請好友即可獲得三級回饋：</p>
			  <p>團隊佣金：ABC返傭8% 3% 1%</p>
			  <p>對方儲值1000USDT 您將獲得 80USDT A級團隊 8%</p>
			  <p>對方儲值1000USDT 您將獲得 30USDT B級團隊 3%</p>
			  <p>對方儲值1000USDT 您將獲得 10USDT C級團隊 1%</p>
			  <p>邀請用戶個值越多,獲得的獎勵就越多更多問題請聯格線上客服.</p> -->
        </div>
        <van-divider style="margin-top: 0; margin-bottom: 0; color: #5e6469" />
        <div
          style="
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color: red;
            background: #000000;
            border-top: 1px solid red;
            padding: 10px;
            border-radius: 0 0 8px 8px;
          "
          class=""
        >
          <button
            size="default"
            type="default"
            style="
              color: #ffffff;
              backgroundcolor: #1f1f1f;
              bordercolor: #1f1f1f;
              width: 80px;
              height: 30px;
            "
            hover-class="is-hover"
            @click="closemsg()"
          >
            关闭
          </button>

          <!-- <div style="color: #fff; width: 40px; height: 30px; padding-left: 150px;background: #a5b1b9;" class="">關閉</div> -->
        </div>
        <!-- <img class="imgs" src="../../assets/img/vipxuzhi.png" alt=""/>
        <div class="ct">
          <div
            style="
              color: #ffffff;
              position: absolute;
              top: 0.4rem;
              right: 0.5rem;
            "
            @click="closemsg()"
          >
            X
          </div>
          <div class="text" v-html="info.text"></div>
        </div> -->
      </div>
    </popup>
  </div>
</template>

<script>
import {
  apiHangqing,
  apiRobots,
  apiUser_mas2,
  apibuyrobots,
  apimyrobots,
  apiwenzhang,
  getMoble,
  apimyshouyi,
  apizsrobots,
  apifeerobot,
  getjiqinum,
  getKlinedata
} from '@/http/api/'
import popup from '@/components/popup/index.vue'
import textScrolling from '@/components/text-scrolling'
import pako from 'pako'
import ROBOT from '@/components/robot'
import { mapGetters } from 'vuex'
import axios from 'axios'
import {
  Button,
  Badge,
  NoticeBar,
  Swipe,
  SwipeItem,
  Col,
  Row,
  Popup,
  Cell,
  Field
} from 'vant'
export default {
  components: {
    textScrolling,
    popup,
    ROBOT,
    [Badge.name]: Badge,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,

    [Cell.name]: Cell,
    [Field.name]: Field
  },
  data() {
    return {
      icon: {
        smile: require('@/assets/icon/smile-icon.png'),
        msg: require('@/assets/icon/bell-icon.png'),
        tz: require('@/assets/icon/tz.png')
      },
      bannerList: [
        require('@/assets/img/trq1.png'),
        require('@/assets/img/trq.png'),
        require('@/assets/img/YT.png'),
        require('@/assets/img/tm.png'),
        require('@/assets/img/58.jpg'),
        require('@/assets/img/173.jpg')
      ],
      show: true,
      btc: '',
      fil: '',
      eth: '',
      token: '',
      info: [],
      iosdown: '',
      anddown: '',
      geren: '',
      aituring: [],
      HQlist: [],
      msgtz: 0,
      lingai: [],
      str: '',
      str2: '',
      str3: '',
      scrollData: [
        {
          name: '+333***222',
          money: '+$12200.9'
        },
        {
          name: '+312***332',
          money: '+$3320.1'
        },
        {
          name: '+333***2442',
          money: '+$1200.6'
        },
        {
          name: '+333***222',
          money: '+$12200.9'
        },
        {
          name: '+312***332',
          money: '+$3320.1'
        },
        {
          name: '+333***2442',
          money: '+$1200.6'
        }
      ],
      klinedata: [],
      coinList: {
        btc: require('@/static/images/icon/btc.png'),
        eth: require('@/static/images/icon/eth.png'),
        bnb: require('@/static/images/icon/bnb.png'),
        xrp: require('@/static/images/icon/xrp.png'),
        ada: require('@/static/images/icon/ada.png'),
        sol: require('@/static/images/icon/sol.png'),
        doge: require('@/static/images/icon/doge.png'),
        dot: require('@/static/images/icon/dot.png'),
        ltc: require('@/static/images/icon/ltc.png'),
        trx: require('@/static/images/icon/trx.png')
      }
    }
  },
  computed: {
    ...mapGetters(['partnerList']),
    navList() {
      return [
        {
          title: this.$i18n.t('navbar.储值'),
          img: require('@/static/images/icon/1.png'),
          url: '/serveWay'
        },
        {
          title: this.$i18n.t('navbar.提取'),
          img: require('@/static/images/icon/2.png'),
          url: '/drawings'
        },
        {
          title: this.$i18n.t('navbar.帮助'),
          img: require('@/static/images/icon/3.png'),
          url: '/helpCenter'
        },

        {
          title: this.$i18n.t('navbar.团队'),
          img: require('@/static/images/icon/4.png'),
          url: '/teaminfo'
        }
      ]
    },
    navList2() {
      return [
        {
          title: this.$i18n.t('navbar.下载APP'),
          img: require('@/static/images/icon/5.png'),
          url: '/appdown'
        },
        {
          title: this.$i18n.t('navbar.活动'),
          img: require('@/static/images/icon/6.png'),
          url: '/huodong'
        },
        {
          title: this.$i18n.t('navbar.代理合作'),
          img: require('@/static/images/icon/7.png'),
          url: '/team'
        },

        {
          title: this.$i18n.t('navbar.邀请朋友'),
          img: require('@/static/images/icon/8.png'),
          url: '/team'
        }
      ]
    }
  },
  created() {
    var token = localStorage.getItem('key')

    if (!token) {
      this.$router.replace('/login')
    }
    this.msgtz = localStorage.getItem('msg')

    this.token = token
    apiUser_mas2({
      userid: token
    }).then((res) => {
      // 未登录踢下线
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      // this.showdown = res.user.showdown
      this.info = res.user
      this.rate = res.user.rate
      this.iosdown = res.user.iosdown
      this.anddown = res.user.anddown
    })
    apiRobots({}).then((res) => {
      this.aituring = res.info
    })
    apimyrobots({ userid: token }).then((res) => {
      this.myaicount = res.info.length
    })
    apimyshouyi({ userid: token }).then((res) => {
      this.geren = res.info

      this.geren.geren_amount = res.info.geren_amount
    })
    // 0号机器人
    apizsrobots({ userid: token }).then((res) => {
      this.lingai = res.info
    })

    getKlinedata({}).then((res) => {
      this.klinedata = res.data
    })
  },
  mounted() {
    this.getHQ()
    if (this.msgtz == 1) {
      this.$refs.popup.show()
    }
    this.homeNotice()
    console.log(this.HQlist)
    this.startTimer()
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    this.clearTimer()
  },
  methods: {
    gokf(){
      window.location.href = this.info.kf
    },
    changlang() {
      this.$router.push('/lang')
    },
    async fetchBackendData() {
      try {
        const response = await axios.get(
          'https://ccc.zetagl0bal.com/Home/Index/getKlinedata'
        )
        // console.log(response.data.data)
        this.klinedata = response.data.data
        //this.klinedata.open>this.klinedata.close
        if (this.klinedata.open > this.klinedata.close) {
          this.klinedata['isz'] = true
        } else {
          this.klinedata['isz'] = true
        }
        // console.log(this.klinedata.open-this.klinedata.close/this.klinedata.close);
        //   getKlinedata({}).then((res)=>{
        // this.klinedata=res.data
        //  }).catch(error => {
        // // 处理错误
        // console.error('发生错误:', error);
        //  });
      } catch (error) {
        console.error('调用后台接口出错:', error)
      }
    },
    startTimer() {
      // 设置定时器，每毫秒更新一次count
      this.timer = setInterval(() => {
        this.fetchBackendData()
      }, 1000 * 30)
    },
    clearTimer() {
      // 清除定时器
      clearInterval(this.timer)
    },

    homeNotice() {
      var tell = /(\d{2})\d*(\d{4})/
      let jiqiNum = Math.floor(Math.random() * 5 + 1)
      jiqiNum = this.getjiqinum(jiqiNum)
      let telNum = getMoble()
      telNum = telNum.replace(tell, '4****$2')

      this.str = telNum + this.$t('升级') + jiqiNum

      jiqiNum = Math.floor(Math.random() * 5 + 1)
      jiqiNum = this.getjiqinum(jiqiNum)
      telNum = getMoble()
      telNum = telNum.replace(tell, '4****$2')
      this.str2 = telNum + this.$t('升级') + jiqiNum

      jiqiNum = Math.floor(Math.random() * 5 + 1)
      jiqiNum = this.getjiqinum(jiqiNum)
      telNum = getMoble()
      telNum = telNum.replace(tell, '4****$2')
      this.str3 = telNum + this.$t('升级') + jiqiNum

      this.str3 = this.str + this.str3
    },
    // 转换机器人
    getjiqinum(num) {
      if (num == 0) {
        return this.$t('No.0')
      } else if (num == 1) {
        return this.$t('No.1')
      } else if (num == 2) {
        return this.$t('No.2')
      } else if (num == 3) {
        return this.$t('No.3')
      } else if (num == 4) {
        return this.$t('No.4')
      } else if (num == 5) {
        return this.$t('No.5')
      }
    },
    qianyuebuy() {
      this.$router.push({
        name: 'myRobot',
        query: {
          info: this.lingai,
          type: 100
        }
      })
    },
    closemsg() {
      this.msgtz = 0
      this.$refs.popup.hide()
      window.localStorage.removeItem('msg')
    },
    about() {
      window.location.href = 'https://web.plus500at.com/'
    },
    getHQ() {
      var that = this
      apiHangqing({}).then((res) => {
        res.forEach(function (item, index) {
          if (item.name == 'BTC' || item.name == 'ETH' || item.name == 'XCH') {
            that.HQlist.push(item)
          }
        })
        this.btc = res[0].last
        this.fil = res[1].last
        this.xch = res[2].last
        this.doge = res[3].last
        this.bch = res[4].last
        this.zec = res[5].last
        this.eth = res[6].last
        this.eos = res[7].last
        this.ltc = res[8].last
        this.etc = res[9].last
        this.sendSocket()
      })
    },
    sendSocket() {
      let t = this
      this.socket = new WebSocket('wss://api-aws.huobi.pro/ws')
      this.socket.onopen = this.open
      // 监听socket消息
      this.socket.onmessage = this.getMessage
    },
    open: function () {
      // console.log("socket连接成功");
      let arr = [
        'btcusdt',
        'filusdt',
        'xchusdt',
        'dogeusdt',
        'bchusdt',
        'zecusdt',
        'ethusdt',
        'eosusdt',
        'ltcusdt',
        'etcusdt'
      ]
      var _that = this.socket
      arr.forEach(function (item, index) {
        let subK = {
          // 订阅数据
          sub: 'market.' + item + '.kline.1min',
          id: item
        }
        _that.send(JSON.stringify(subK))
      })
    },
    getMessage: function (event) {
      let blob = event.data
      let reader = new FileReader()
      var _that = this
      reader.onload = function (e) {
        let ploydata = new Uint8Array(e.target.result)
        let msg = pako.inflate(ploydata, {
          to: 'string'
        })
        msg = JSON.parse(msg)
        //console.log(msg);
        // _that.lists = [];
        let name = ''
        let fu = ''
        let bili = '0.00'
        let color = ''
        let home = ''
        let zhi = ''
        let isUp = false
        if (msg.ch) {
          if (msg.ch == 'market.btcusdt.kline.1min') {
            name = 'BTC（USDT)'

            // 计算比例
            if (msg.tick.close >= _that.btc) {
              fu = '+'
              bili = ((msg.tick.close - _that.btc) / _that.btc) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.btc - msg.tick.close) / _that.btc) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }

            // _that.lists[0] = {'id':1,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};

            _that.HQlist[0].name = name
            _that.HQlist[0].val = msg.tick.vol
            _that.HQlist[0].change = fu + bili
            _that.HQlist[0].isUp = isUp
            _that.HQlist[0].last = msg.tick.close
          }
          if (msg.ch == 'market.filusdt.kline.1min') {
            name = 'FIL(USDT)'
            // 计算比例
            if (msg.tick.close >= _that.fil) {
              fu = '+'
              bili = ((msg.tick.close - _that.fil) / _that.fil) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.fil - msg.tick.close) / _that.fil) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }
            // _that.lists[1] = {'id':2,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
            _that.HQlist[1].name = name
            _that.HQlist[1].val = msg.tick.vol
            _that.HQlist[1].change = fu + bili
            _that.HQlist[1].last = msg.tick.close
            _that.HQlist[1].isUp = isUp
          }

          if (msg.ch == 'market.ethusdt.kline.1min') {
            name = 'ETH(USDT)'
            // 计算比例
            if (msg.tick.close >= _that.eth) {
              fu = '+'
              bili = ((msg.tick.close - _that.eth) / _that.eth) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.eth - msg.tick.close) / _that.eth) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }
            // _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
            _that.HQlist[2].name = name
            _that.HQlist[2].val = msg.tick.vol
            _that.HQlist[2].change = fu + bili
            _that.HQlist[2].isUp = isUp
            _that.HQlist[2].last = msg.tick.close
          }
        }
      }
      reader.readAsArrayBuffer(blob, 'utf-8')

      var timea = Math.round(new Date() / 1000)

      // console.log(this.HQlist);
      this.$forceUpdate()
      var _thats = this.socket
      setTimeout(function () {
        _thats.send(
          JSON.stringify({
            pong: timea
          })
        )
      }, 4000)
    },

    send: function () {
      var timea = Math.round(new Date() / 1000)
      var data = {
        pong: timea
      }
      console.log(data)
      this.socket.send(data)
    },
    downapp() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        window.location.href = 'https://plus500at.com/app.mobileconfig'
      } else {
        window.location.href = 'https://plus500at.com/app.apk'
      }
    },
    handleUrl(url) {
      window.location.href = url
    },
    // 切换语言
    changeL() {
      this.$i18n.locale = 'zh'
    },
    handleTo(url) {
      console.log(url)
      if ('/appdown' == url) {
        this.downapp()
      } else {
        this.$router.push(url)
      }
    },
    handleRobot() {
      this.$router.push('/robot')
    },
    // 购买
    handleRobot2(info, type) {
      this.$router.push({
        name: 'robot',
        query: {
          info: info,
          type: type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.customer {
  position: fixed;
  bottom: 140px;
  right: 0;
  width: 50px;
  height: 180px;
  background-color: #f8e573;
  font-size: 26px;
  writing-mode: vertical-rl;
  text-align: center;
  line-height: 50px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  font-weight: bold;
  z-index: 999;
}

.smile-icon {
  width: 80px;
  height: 80px;
}
.title {
  line-height: 80px;
}
.msg-icon {
  width: 26px;
  height: 38px;
}
.content {
  .menus {
    .menu {
      justify-content: space-between;
      .menu-item {
        .cover {
          width: 120px;
          height: 120px;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAMSSURBVGiB7ZpNaxNRFIafc2YynZRGa62timJREelCN4IgiN3qRhcuBBf6FwT3/Qm6cusfcOFOBEGX4sZFwW2lflQtta1JM2mazHExiaQ1sYmZOs3FZ5PhDoT3zbn3ZIbzCj1ii0xRZwbhOiJHMZlENACNQMrJpxchWkY0IvaiGD9S9cqIHxEHEeQivKCM5CMII2SkjBQidDza1MkoF04Xo/q5Uj5/KgKqQElE4l50SteGPnIX5Q7mn0aG38XeiefqH1vAv/iRfbOLvf5Anak0NIW2/QawJiKVbr5lR2P2mRsYD8D38c8+ZGLuSa9SU6YGLO9k8I/G7DMPMP/mHjG0nSKw2mmLartFW2TKPvEWGTtG4d6tPWgKoABMmpnf7uZvFbNFpoh5iXf8KZMLD3ddXv/UgK8iUmtd3GLM5hkl4CXe8VcDYqpJVUS2NLCtW3GIWWTftwEzBRCY2Vjrwq/9aR+YwfybjD+69u91pULBzCoiUobWiimPCa/cJ7j9IzNp/XOgeaHQqBbDXxh78TozSengm9kINCvmMYt/ci+29L9hBEBsnlGG9D1H6uezVpQin5Qh7iCjz7JWkjKhEus03sRc1kpSZlhRPUNw6U3WSlLGU5BxZKqYtZKU8dVMc4QXXDOmKqLrBFfXs1aSNpq8zruHgu+qMXXVmLMV89w0FrtqTP3AuVYPoLHloqxF7AaqGrpZMQjcPGNI3lVjhf/GBglFJtw0tqmH3TSWC8862u6Dy27+QcNEPWsRu4ECPQ2tB4RYSQZnrlFTwMWtuKmAi+2+qiT5CdcoaWMovZG1khSpiEjcnGiWMpWSLmvQGPyJSAk3umOtmdhpTQ2sZCQmTZabF7+MNabtgzycKLbmq7bkPETkO0mMbtCoAqutC+2yVEsM1nmrAUvbw2Jt02+N4NUhIPgHwvqhSmLqt0J0jPWZmQKjJCmzvUixcXTasnMQ0ywEDtIST8qYGrDSjBZ1ovvobGJwPxD2Kexv2SDJBnf1MNG1sSaNLTpCcv5yJJVsG+jsg5ikMnWSh/RKu3P0J34C3NvjqHVCAlkAAAAASUVORK5CYII=)
            no-repeat center;
          img {
            width: 80px;
            height: 80px;
          }
        }
        title {
          font-size: 28px;
        }
      }
    }
  }
  .notice {
    height: 58px;
    background: rgb(77, 77, 77);
    border-radius: 29px 29px 29px 29px;
    color: #fff;
    .tz-icon {
      width: 19px;
      height: 28px;
    }
  }
  .coins {
    justify-content: space-between;
    .coin {
      border-radius: 32px;
      width: 250px;
      padding: 32px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      background-color: #0e0e0e;
      .coin-title {
        .coin-icon {
          width: 50px;
          height: 50px;
        }
      }
      .coin-value {
        font-size: 40px;
      }
      .coin-percent {
        line-height: 56px;
        color: #ff6565;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #3a3a3a;
        width: fit-content;
        border-radius: 30px;
      }
    }
    .coin::before {
      content: '';
      position: absolute;
      width: 150px;
      height: 150px;
      background-color: #ff6565;
      filter: blur(50px);
      bottom: -40px;
      right: -40px;
      border-radius: 50%;
      z-index: 0;
      opacity: 0.8;
    }
  }

  .myad {
    background: url('@/static/images/icon/banner.png') no-repeat center;
    background-size: cover;
    height: 240px;
    border-radius: 44px;
    margin-top: 50px;
    box-sizing: border-box;
    padding: 80px;
    .myad-title {
      font-size: 60px;
      color: #fff;
    }
    .myad-subtitle {
      font-size: 26px;
      margin-top: 20px;
    }
  }

  .invite {
    border-radius: 50px;
    background-color: #7b59d7;
    padding-left: 64px;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    .invite-title {
      font-size: 42px;
    }
    .invite-subtitle {
      color: #794d2d;
      background-color: #ffe500;
      border-radius: 50px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 30px;
    }
    .invite-hand {
      position: absolute;
      right: 50px;
      top: -32px;
      width: 200px;
      height: 135px;
    }
  }
  .markets {
    .markets-title {
      font-size: 32px;
      margin-top: 50px;
    }
    .market {
      padding: 50px;
      border-radius: 50px;
      .market-title {
        .market-title-icon {
          width: 50px;
          height: 50px;
        }
        .market-title-subtitle {
          font-size: 28px;
          font-weight: bold;
          span {
            font-size: 24px;
            color: #a5b1b9;
            font-weight: unset;
          }
        }
      }
      .market-items {
        flex-wrap: wrap;
        justify-content: space-between;
        .market-item {
          border-radius: 10px;
          background-color: #000;
          width: 280px;
          height: 150px;
          padding: 20px;
          padding-top: 10px !important;
          box-sizing: border-box;
          .market-item-title {
            width: 100%;
            font-size: 26px;
            .market-item-title-icon {
              width: 40px;
              height: 40px;
            }
          }
          .market-items-main {
            justify-content: space-between;
            color: #09c497;
            .market-items-main-value {
            }
            .market-items-main-percent {
            }
          }
        }
      }
    }
  }
  .cal {
    border-radius: 50px;
    justify-content: center;
    padding: 50px 30px;
    .cal-item {
      width: 50%;
      .cal-item-title {
        font-size: 28px;
      }
      .cal-item-content {
        font-size: 28px;
        margin-top: 15px;
        font-weight: bold;
        color: #ffe500;
      }
    }
  }
  .nav-bg {
    margin-top: 20px;
    background-color: rgb(77, 77, 77) !important;
    padding-top: 50px;
    border-radius: 59px;

    .nav {
      &-item {
        .img-icon {
          width: 120px;
          height: 120px;
        }
        width: 25%;
      }
    }
  }
  .about {
    .child {
      width: 20px;
      height: 20px;
      background: #0d9135;
      border-radius: 50%;
    }
    .jiantou-w {
      width: 28px;
    }
    .about {
      width: 300px;
      height: 150px;
    }
  }
  .robot {
    height: 128px;
    background-color: #4d4d4d;
    border-radius: 20px;
    .robot-img {
      width: 120px;
      height: 120px;
    }
    .jiantou {
      width: 34px;
      margin-right: 40px;
    }
  }

  .laste {
    background-color: rgb(77, 77, 77);
    border-radius: 59px;
    padding: 20px 0;
    .van-swipe {
      /* height: 337px; */
      .van-swipe-item {
        padding: 20px;
        /* width: 405px; */
        /* height: 337px; */
        margin-left: 32px;
        background: linear-gradient(
          165deg,
          #0d9135 0%,
          rgba(255, 217, 89, 0) 100%
        );
        //background: linear-gradient(1deg,#ffcc8f,#ffedd8);
        box-shadow: 0px 19px 26px 1px rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        opacity: 1;
        .robot-w {
          width: 79px;
          height: 79px;
        }
        .buy {
          background-color: #3a372d;
          height: 100px;
          border-radius: 50px;
          margin-top: 20px;
          .btn {
            height: 60px;
            padding: 0 30px;
            background-color: #44b361;
            border-radius: 30px;
            line-height: 60px;
          }
        }
      }
    }
    .jiantou {
      width: 32px;
    }
  }
  .partner {
    background-color: rgb(77, 77, 77);
    border-radius: 59px;
    .partner-item {
      width: 32%;
      margin-bottom: 20px;
      background-color: #2a2c2e;
      height: 58px;
      border-radius: 29px;
      margin-left: 3px;
      .imgIcon {
        width: 154px;
        height: 40px;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  // .bg {
  //   height: 20px;
  //   background-size: cover;
  //   background: url('../../assets/img/会员须知.png') no-repeat;
  // }
  .poptip {
    margin-left: 32px;
    margin-right: 32px;
    // height: 442px;
    background: #2a2c2e;
    position: relative;
    // padding: 20px 30px;
    border-radius: 28px;
    .ct {
      padding: 0px 30px 20px;
      .text {
        color: #fff;
        line-height: 50px;
      }
    }
    .imgs {
      width: 100%;
      height: 250px;
      // position: absolute;
      // left: 0;
      // top: 0;
      // transform: translate(-0%, -50%);
    }
  }
}
.bg-card {
  background-color: #232323;
}
.scroll-box {
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 100px;
}

.red {
  color: #ff6565 !important;
}

.green {
  color: green !important;
}
</style>
