<template>
    <div class="copy-input">
      <div class="input-box" ref="inputBox">{{ inputValue }}</div>
      <button @click="copyText">
        <svg style="height: 21px;width: 21px;"  xmlns='http://www.w3.org/2000/svg'>
          <path fill='currentColor' d='M9 18q-.825 0-1.412-.587T7 16V4q0-.825.588-1.412T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.587 1.413T18 18zm0-2h9V4H9zm-4 6q-.825 0-1.412-.587T3 20V6h2v14h11v2zm4-6V4z'/>
        </svg>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        inputValue: this.value
      };
    },
    methods: {
      copyText() {
        const inputBox = this.$refs.inputBox;
        const range = document.createRange();
        range.selectNode(inputBox);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        alert('复制成功！');
        window.getSelection().removeAllRanges();
      }
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue;
      }
    }
  };
  </script>
  
  <style scoped>
  .copy-input {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius:15px;
    background-color: #00000029;
    height: 80px;
    line-height:80px;
   
  }
  
  .input-box {
    padding: 20px;
    font-size: 28px;
    overflow: hidden;
    flex: 1;
  }
  
  button {
    margin-left: 10px;
    margin-right: 20px;
    padding: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #ffe500;
    font-size: 42px;
    
  }
  </style>
  