import { post } from './request'
import axios from "axios";
//登录接口
export const apiLogin = (p) => post('Home/Login/submit', p)
// 获取验证码
export const apiCode = (p) => post('Home/Login/code', p)
// 注册
export const apiRegister = (p) => post('Home/Login/register', p)
// u退出登录
export const apiLoginout = (p) => post('Home/Login/loginout', p)
// 发送短信
export const apiRegss = (p) => post('Home/Login/forget_mobile', p)
// 提交找回密码
export const apiForget = (p) => post('Home/Login/forget', p)
// 获取行情
export const apiHangqing = (p) => post('Home/Login/Market', p)
// 获取用户信息
export const apiUser_mas2 = (p) => post('Home/Home/getUserinfo', p)
// 获取银行卡
export const apiUser_bank_mas = (p) => post('Home/User/user_bank_mas', p)
// 添加银行卡
export const apiBank_add_ajax = (p) => post('Home/User/bank_add_ajax', p)
// 获取银行卡名称
export const apiUser_bank_type = (p) => post('Home/User/user_bank_type', p)
// 机器人列表
export const apiRobots = (p) => post('Home/Interface/Quantitative', p)
// 获取客服
export const apigetkf = (p) => post('Home/Interface/CustomerService', p)
// 购买机器人
export const apibuyrobots = (p) => post('Home/Interface/buyrobots', p)
// 我的机器人Home/NewProj/myrobots
export const apimyrobots = (p) => post('Home/Interface/myQuantitative', p)
// 激活机器人
export const apiactrobots = (p) => post('Home/Interface/actrobots', p)
// 赠送机器人
export const apigiverobots = (p) => post('Home/Interface/giverobots', p)
// 获取文章
export const apiwenzhang = (p) => post('Home/Login/wenzhang', p)
// 正在做单的机器人zuodan
export const apizuodan = (p) => post('Home/Home/Dosingle', p)
// 获取get参数
export function getUrlKey(name, url) {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) ||
        [,''])[1].replace(/\+/g, '%20')
    ) || null
  )
}
// 修改密码
export const apipassword = (p) => post('Home/User/password', p)
// 获取汇率
export const apigetHuilv = (p) => post('Home/Home/ExchangeRate', p)
// 充值 chongzhi
export const apichongzhi = (p) => post('Home/Home/PaymentAddress', p)
// 充值2 usdt
export const apichongzhiusdt = (p) => post('Home/Home/apichongzhiusdt', p)
// 充值 rmb
export const apichongzhirmb = (p) => post('Home/Home/Bankpay', p)
// 提现
export const apitixian = (p) => post('Home/Home/Withdrawal', p)
// 暂停机器人
export const apistop = (p) => post('Home/Interface/stop', p)
// 我的机器人收益/Home/NewProj/getearnings
export const apigetearnings = (p) => post('Home/Interface/getearnings', p)
//  获取正在做单数据 actionai
export const apiactionai = (p) => post('Home/Home/actionai', p)
//  获取存款 消费记录
export const apiliushui = (p) => post('Home/Home/billList', p)
// 获取消费  xiaofei
export const apixiaofei = (p) => post('Home/Home/consumption', p)
// 代理返佣 Home/New'Proj/getagent
export const apigetagent = (p) => post('Home/Interface/getagent', p)
//  团队数据teamData
export const apiteamData = (p) => post('Home/Home/getTeaminfo', p)
// 我得数据 myshouyi
export const apimyshouyi = (p) => post('Home/Home/Profits', p)
// 我得会员数据userlist
export const apiuserlist = (p) => post('Home/Home/userlist', p)
// 我得会员等级lerverdata
export const apilerverdata = (p) => post('Home/Home/getLevelUser', p)
// 随机生成手机号码
export function getMoble() {
  var prefixArray = new Array(
    '13',
    '12',
    '14',
    '15',
    '16',
    '21',
    '23',
    '25',
    '31',
    '43'
  )
  var i = parseInt(10 * Math.random())
  var prefix = prefixArray[i]
  for (var j = 0; j < 8; j++) {
    prefix = prefix + Math.floor(Math.random() * 10)
  }
  return prefix
}
// 绑定钱包地址
export const apibindqianbao = (p) => post('Home/Home/BindAddress', p)
// 获取我得钱包地址 getaddres
export const apigetaddres = (p) => post('Home/Home/getaddres', p)
// 获取0号机器人 Home/NewProj/zsrobots
export const apizsrobots = (p) => post('Home/Interface/zsrobots', p)
// 购买0号机器人 Home/NewProj/feerobot
export const apifeerobot = (p) => post('Home/Interface/feerobot', p)
// 上传图片
export const apiuploadimg = (p) => post('Home/Home/headimg_img', p)
// 出售会员
export const apisellerrobots = (p) => post('Home/Interface/sellerrobots', p)
// 消息提醒
export const apihuoqumsg = (p) => post('Home/Home/huoqumsg', p)
// 设置消息已读
export const apimsgread = (p) => post('Home/Home/msgread', p)
// 购买出售机器人
export const apiconfirm = (p) => post('Home/Interface/confirm', p)
// 拒绝出售机器人
export const apirefuse = (p) => post('Home/Interface/refuse', p)
// 获取收入记录
export const apishourujilu = (p) => post('Home/Home/FlowRecord', p)
// 获取  支付接口
export const apipayapi = (p) => post('Home/Home/payapi', p)
export const apipayapi2 = (p) => post('Home/Home/payapi2', p)
// 获取支付通道
export const apipaytongdao = (p) => post('Home/Home/paytongdao', p)
// 获取机器人code  apigetCode
export const apigetCode = (p) => post('Home/Home/getCode', p)
// 已售机器人
export const apichushou = (p) => post('Home/Home/sellList', p)
// 修改或者设置支付密码
export const apipaypassword = (p) => post('Home/User/paypassword', p)
// 发送短信 充值支付密码 paysms
export const apipaysms = (p) => post('Home/User/paysms', p)
// 获取短信开关
export const apismssWitch = (p) => post('Home/Login/smssWitch', p)
// 机器人体验激活
export const apiexperience_act = (p) => post('Home/Interface/experience_act', p)
// 删除银行卡
export const apidelbank = (p) => post('Home/User/delbank', p)
// 验证支付密码 VerifyPaypass
export const apiVerifyPaypass = (p) => post('Home/User/VerifyPaypass', p)
// 修改银行卡 editcard_ajax
export const apieditcard_ajax = (p) => post('Home/User/editcard', p)
// 验证码获取银行卡号 getCodeBank
export const getCodeBank = (p) => post('Home/User/getCodeBank', p)
// 获取邀请列表apikongtou
export const apikongtou = (p) => post('Home/Home/kongtou', p)
// 领取收益
export const apiky_buy = (p) => post('Home/Home/ky_buy', p)

// 手动usdt充值 rechargeUsdt
export const rechargeUsdt = (p) => post('Home/Home/rechargeUsdt', p)
// 获取国码
export const apigetGuoma = (p) => post("Home/Login/getGuoma", p);
export const postimg = (url, params)=> {
  return new Promise((resolve, reject) => {
	  axios.post(url, params,{
		  headers:{"Content-Type":"multipart/form-data"}
	  })
      .then((res) => {
        if(res.data.status==1){
			resolve(res.data);
		}else{
			
			resolve(res.data);
		}
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
// 提交活动
export const subActivity = (p) => post('Home/Home/subActivity', p)

export const getActivity = (p) => post('Home/Home/getActivity', p)

export const getVipList = (p) => post('Home/Index/getVipList', p)

export const getKlinedata = (p) => post('Home/Index/getKlinedata', p)


// 购买vip
export const buyVip = (p) => post('Home/Index/buyVip', p)


