<template>
  <div class="page">
    <div class="head">
      <div class="left">
        <!-- <img class="back" src="@/assets/icon/back.png" alt="" /> -->
      </div>
      <div class="right" @click="changlang">
        <div class="txt">{{ getLangStr($i18n.locale) }}</div>
        <div class="triangle"></div>
      </div>
    </div>
    <div class="ctn">
      <div class="logo">
        <img class="logo-in" src="@/static/images/icon/avatar.webp" alt="" />
      </div>
      <div class="title">Dogecoin</div>
      <div class="from-box">
        <div class="form">
          <div class="form-nav">
            <div
              :class="type === 0 ? 'nav active' : 'nav'"
              @click="changeType(0)"
            >
              {{ $t('邮箱登录') }}
            </div>
            <div
              :class="type === 1 ? 'nav active' : 'nav'"
              @click="changeType(1)"
            >
              {{ $t('手机号登录') }}
            </div>
          </div>
          <div class="form-type" v-if="type === 0">
            <div class="form-item">
              <div class="label">{{ $t('邮箱') }}</div>
              <input
                class="form-item-input"
                type="text"
                v-model="username"
                :placeholder="$t('请输入邮箱')"
              />
            </div>
            <div class="form-item">
              <div class="label">{{ $t('密码') }}</div>
              <input
                class="form-item-input"
                :type="inputType"
                v-model="password"
                :placeholder="$t('请输入密码')"
              />
              <div class="txt" @click="clickCheck">{{ $t('查看') }}</div>
            </div>
          </div>
          <div class="form-type" v-if="type === 1">
            <div class="form-item">
              <div class="label label2">
                <fdSelect :list="list" v-model="selected"></fdSelect>
              </div>
              <input
                class="form-item-input"
                type="text"
                v-model="username"
                :placeholder="$t('请输入密码')"
              />
            </div>
            <div class="form-item">
              <div class="label">{{ $t('密码') }}</div>
              <input
                class="form-item-input"
                :type="inputType"
                v-model="password"
                :placeholder="$t('请输入密码')"
              />
              <div class="txt" @click="clickCheck">{{ $t('查看') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-box">
        <button class="login-btn" @click="onSubmit">{{ $t('登录') }}</button>
      </div>
      <div class="tip">
        {{ $t('没有账号') }} ?
        <router-link class="btn" to="/register">
          {{ $t('注册') }}
        </router-link>
      </div>
      <div class="tip">
        <router-link class="btn" to="/forgetpass">
          {{ $t('忘记密码') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { apiLogin, apigetGuoma } from '@/http/api/' // 导入我们的api接口
import { Toast } from 'vant'
import fdSelect from '@/components/fd-select/fd-select.vue'
export default {
  components: {
    fdSelect
  },
  data() {
    return {
      type: 0,
      target: false,
      inputType: 'password',

      username: '',
      password: '',
      guoma: '',
      list: [],
      selected: {} //选中国旗拿到的数据
    }
  },
  created() {
    this.gethuoma()
  },
  methods: {
    changeType(type) {
      this.type = type
    },
    clickCheck() {
      this.target = !this.target
      if (this.target) {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    gethuoma() {
      apigetGuoma({}).then((res) => {
        this.list = res.info.map((v) => {
          return {
            ...v
            //img:require(`@/static/images/country/${v.code.replace(/\+/g,'')}.png`),
          }
        })
        this.guoma = this.list[0].code
        this.selected = this.list[0]
      })
    },
    changlang() {
      this.$router.push('/lang')
    },
    onSubmit() {
      Toast.loading({
        duration: 0,
        message: this.$t('加载中'),
        forbidClick: true
      })

      apiLogin({
        mobile: this.username,
        password: this.password,
        optionval: this.selected.code
      }).then((res) => {
        if (res.status == 1) {
          setTimeout(() => {
            // 存储用户登录状态
            localStorage.setItem('key', res.userid)
            localStorage.setItem('msg', 1)
            Toast.clear()
            Toast.success(this.$t('登录成功'))

            this.$router.replace('/')
          }, 1000)
        } else {
          Toast.fail(this.$t(res.info))
        }
      })
    },
    getLangStr(key) {
      switch (key) {
        case 'en':
          return 'English'
        case 'zh':
          return '简体中文'
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$them-color: #ffe500;

.page {
  width: 100%;
  height: 100%;
  background-color: #000000;

  .head {
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;

    .back {
      width: 27px;
      height: 27px;
    }

    .right {
      display: flex;
      align-items: center;
      color: #fff;
    }

    .txt {
      line-height: 12px;
    }

    .triangle {
      margin-left: 10px;
      width: 0;
      height: 0;
      border-left: 14px solid $them-color;
      border-right: 24px solid transparent;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
    }
  }

  .ctn {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-top: 100px;
      width: 170px;
      height: 170px;
      border: 2px solid #ccc;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 10px;

      .logo-in {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      margin: 20px 0;
      width: 100%;
      text-align: center;
      font-size: 32px;
      color: $them-color;
    }

    .from-box {
      margin-top: 30px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 50px;

      .form {
        width: 100%;
        background-color: #191a1a;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 0 30px;
        padding-bottom: 20px;

        .form-nav {
          height: 98px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .nav {
            width: 50%;
            font-size: 28px;
            text-align: center;
            font-weight: bold;
            color: #828c8e;
          }

          .active {
            color: $them-color;
          }
        }
      }

      .form-item {
        width: 100%;
        height: 98px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #353737;
        border-radius: 10px;
        margin: 20px 0;

        .label {
          flex: none;
          width: 180px;
          text-align: center;
          color: #fff;
        }

        .label2 {
          height: 50px;
          width: 180px;
        }

        .form-item-input {
          width: 100%;
          color: #fff;
        }

        .txt {
          width: 100px;
          flex: none;
          text-align: center;
          color: #fff;
        }
      }
    }

    .login-box {
      width: 100%;
      box-sizing: border-box;
      padding: 60px;

      .login-btn {
        width: 100%;
        height: 98px;
        border-radius: 49px;
        background-color: $them-color;
        font-size: 32px;
        color: #000;
      }
    }

    .tip {
      margin: 20px 0;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 28px;

      .btn {
        color: $them-color;
      }
    }
  }
}
</style>